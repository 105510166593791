<template>
  <div class="bindPhone">
    <div class="navBar">
      <div @click="backPage">
        <svg-icon class="navArrow" iconClass="navArrow" />
      </div>
      <div class="navTitle">绑定手机号码</div>
      <div class="rightBtn" @click="showHelp = true">帮助</div>
    </div>

    <div class="main">
      <div class="bindTitle">绑定手机号码</div>
      <div class="WelcomeMessage">欢迎来到涩漫天堂 二次元的“性”世界</div>
      <!-- 手机号 -->
      <div class="inputBox">
        <div class="areaCodeBox">
          <router-link tag="div" to="/countryCode" class="areaCode">{{
            areaCode
          }}</router-link>
          <svg-icon class="areaCodeArrow" iconClass="navArrow" />
        </div>
        <van-field
          class="inputItem"
          v-model="phoneVal"
          placeholder="请输入手机号"
          @blur="changBlur"
        >
          <template #button v-if="phoneVal">
            <div @click="clearPhone">
              <svg-icon class="inputClose" iconClass="inputClose" />
            </div>
          </template>
        </van-field>
      </div>
      <!-- 验证码 -->
      <div class="inputBox mt44">
        <van-field
          class="inputItem"
          label="验证码"
          :maxlength="8"
          v-model="msgVal"
          placeholder="请输入验证码"
          @blur="changBlur"
        >
          <template #button>
            <div class="loading" v-if="loading">
              <van-loading type="spinner" color="#ec8737" size="20" />
            </div>
            <div class="msgCodeBtn" v-else-if="timer">{{ timer }}s后重发</div>
            <div class="msgCodeBtn" v-else @click="sendCode">获取验证码</div>
          </template>
        </van-field>
      </div>
      <!-- 确认协议 -->
      <div class="confirmTipBox">
        <div class="checkIcon" @click="isConfirm = !isConfirm">
          <svg-icon
            class="selectedIcon"
            iconClass="selectedIcon"
            v-if="isConfirm"
          />
          <div class="unselected" v-else></div>
        </div>
        <div @click="showProtocol = true">
          我已经满18岁 已具成年人完整行为能力
        </div>
      </div>

      <div
        class="nextBtn"
        :class="{ isDisableBtn: !phoneVal || !msgVal || !isConfirm }"
        @click="nextStep"
      >
        立即绑定
      </div>

      <!-- <div class="codeHelpBox" @click="showCodeHelp = true">
                <div>收不到验证码？</div>
            </div> -->
    </div>

    <!-- 协议弹窗 -->
    <van-popup v-model="showProtocol" class="popup">
      <div class="popupBox">
        <div class="popTitle">成年人模式</div>
        <div class="popContent">
          如果您未满18岁或您当地法律许可之法定年龄、或是对情色反感或是卫道人士建议您离开本站！本站归类为限制级、限定为成年者已具有完整行为能力且愿接受本站内影音内容、及各项条款之网友才可浏览，未满18岁谢绝进入。
        </div>
        <div class="popBtn">
          <div @click="showProtocol = false">我已满18岁</div>
        </div>
      </div>
    </van-popup>
    <!-- 验证码帮助 -->
    <van-popup v-model="showCodeHelp" class="popup">
      <div class="popupBox">
        <div class="popTitle">收不到验证码？</div>
        <div class="codeHelpContent">
          1.请检查是否输入正确的手机号码<br />
          2.请检查手机是否停机<br />
          3.请使用其他账号绑定<br />
          4.请联系官方客服
        </div>
        <div class="popBtn">
          <div @click="showCodeHelp = false">我知道了</div>
        </div>
      </div>
    </van-popup>

    <!-- 帮助 -->
    <van-popup v-model="showHelp" class="popup">
      <div class="popupBox">
        <div class="popTitle">绑定手机遇到麻烦？</div>
        <div class="popHelpContent">
          涩漫官方TG群 @yaojingdm1<br /><br />
          咨询24小时在线客服
        </div>
        <div class="popBtn">
          <div @click="consult">立即咨询</div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { Field, Loading, Toast, Popup } from "vant";
import { sendCode, mobileBind } from "@/api/user";
import { mapGetters } from "vuex";
export default {
  components: {
    [Popup.name]: Popup,
    [Field.name]: Field,
    [Loading.name]: Loading,
  },
  data() {
    return {
      // areaCode: "+86", // 区号
      phoneVal: "", // 手机号
      msgVal: "", // 验证码
      isConfirm: false, // 是否确认
      timer: 0, // 倒计时时间
      loading: false, // loading
      showProtocol: false, // 是否显示协议
      showCodeHelp: false, // 是否显示验证码帮助
      showHelp: false, // 是否显示帮助
    };
  },
  computed: {
    ...mapGetters({
      areaCode: "areaCode",
    }),
  },
  methods: {
    changBlur() {
      window.scroll(0, 0); //失焦后强制让页面归位
    },
    // 返回
    backPage() {
      this.$router.go(-1);
    },
    // 清除手机号
    clearPhone() {
      this.phoneVal = "";
    },
    // 倒计时
    countdown() {
      this.timer = 60;
      let countdown = setInterval(() => {
        this.timer--;
        if (this.timer <= 0) {
          clearInterval(countdown);
        }
      }, 1000);
    },
    // 发送验证码
    async sendCode() {
      if (!this.phoneVal) {
        Toast("请输入手机号");
        return;
      }
      this.loading = true;
      let req = {
        mobile: this.areaCode + this.phoneVal,
        type: 1, // 1-绑定手机号  2-手机号登录
      };
      /******** 测试代码 **********/
      // setTimeout(() => {
      //     this.loading = false;
      //     this.countdown();
      // }, 3000)
      /******** 测试代码 **********/
      let ret = await this.$Api(sendCode, req);
      this.loading = false;
      if (ret && ret.code == 200) {
        Toast("验证短信已发出 请注意查收");
        this.countdown();
      } else {
        // console.log(ret.tip || "验证码发送失败");
        Toast(ret.tip || "验证码发送失败");
      }
    },
    // 下一步
    async nextStep() {
      if (!this.phoneVal) {
        Toast("请输入手机号");
        return;
      }
      if (!this.msgVal) {
        Toast("请输入验证码");
        return;
      }
      if (!this.isConfirm) {
        Toast("请勾选协议");
        return;
      }
      let req = {
        code: this.msgVal,
        mobile: this.areaCode + this.phoneVal,
      };
      let ret = await this.$Api(mobileBind, req);
      if (ret && ret.code == 200) {
        this.$router.go(-1);
      } else {
        // console.log(ret.tip || "验证码发送失败");
        Toast(ret.tip || "登录失败");
      }
    },
    // 咨询
    consult() {
      this.showHelp = false;
      this.$router.push("/customerService");
    },
  },
};
</script>

<style lang="scss" scoped>
.bindPhone {
  height: 100%;
  // background: #f5f5f5;

  .navBar {
    height: 44px;
    padding: 0 12px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fff;
    border-bottom: 0.5px solid #f3f3f3;
    .navTitle {
      // flex: 2;
      // text-align: center;
      font-size: 16px;
      color: #000;
    }

    .navArrow {
      width: 17px;
      height: 17px;
    }

    .rightBtn {
      font-size: 14px;
      color: #666;
    }
  }

  .main {
    height: calc(100% - 44px);
    overflow-y: auto;

    .bindTitle {
      font-size: 30px;
      margin: 32px 0 0 28px;
      color: #000;
    }

    .WelcomeMessage {
      margin: 10px 0 56px 28px;
      font-size: 14px;
      color: #999999;
    }

    .inputBox {
      display: flex;
      align-items: center;
      width: 319px;
      margin: 0 auto;
      font-size: 15px;
      padding-bottom: 12px;
      box-sizing: border-box;
      border-bottom: 1px solid #322e61;

      /deep/ .van-field__label {
        width: 44px;
        margin-right: 27px;
        color: #000;
      }

      .areaCodeBox {
        display: flex;
        align-items: center;
        margin-right: 27px;
        color: #000;

        .areaCodeArrow {
          width: 12px;
          height: 12px;
          transform: rotate(-90deg);
          margin-bottom: 4px;
          margin-left: 4px;
        }
      }

      .inputItem {
        padding: 0;
        background: #00000000;

        /deep/ .van-field__control {
          font-size: 14px;
          color: #000;
        }

        ::placeholder {
          color: #999999;
        }

        .inputClose {
          width: 17px;
          height: 17px;
        }

        .msgCodeBtn {
          color: #fc4162;
          font-size: 13px;
        }
      }
    }

    .confirmTipBox {
      font-size: 12px;
      margin: 16px 28px 29px;
      display: flex;
      align-items: center;
      color: #999999;
      .checkIcon {
        margin-right: 10px;
      }

      /deep/ .van-checkbox__label {
        color: #626079;
      }

      /deep/ .van-checkbox__icon {
        display: flex;
        align-items: center;
      }

      .unselected {
        width: 10px;
        height: 10px;
        border: 1px solid #626079;
        border-radius: 50%;
      }

      .selectedIcon {
        width: 12px;
        height: 12px;
      }
    }

    .nextBtn {
      width: 284px;
      height: 44px;
      line-height: 44px;
      font-size: 18px;
      border-radius: 44px;
      color: #424242;
      background: #fbd13d;
      margin: 0 auto;
      text-align: center;
    }

    .isDisableBtn {
      background: #abaaba;
    }

    .codeHelpBox {
      width: 292px;
      font-size: 13px;
      color: #999999;
      margin: 19px auto 20px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }

  .popup {
    // background: #00000000;
    border-radius: 9px;
    .popupBox {
      width: 304px;
      overflow: hidden;
      // background: #1b164c;
      // background: url("../../../assets/png/gradientBg.png") no-repeat;
      border-radius: 18px;
      background: linear-gradient(180deg, #fffce0 0%, #fff 30%);
      background-size: contain;
      color: #000;
      .popTitle {
        font-size: 24px;
        text-align: center;
        margin: 30px 0 18px;
      }
      .popContent {
        font-size: 15px;
        padding: 0 18px;
        line-height: 26px;
        box-sizing: border-box;
      }
      .codeHelpContent {
        font-size: 15px;
        line-height: 26px;
        padding: 0 42px;
        color: #000;
        box-sizing: border-box;
      }
      .popHelpContent {
        font-size: 15px;
        line-height: 26px;
        text-align: center;
      }
      .popBtn {
        width: 244px;
        height: 46px;
        border-radius: 46px;
        font-size: 16px;
        margin: 18px auto 30px;
        // margin-top: 24px;
        // border-top: 1px solid #424242;
        color: #424242;
        background: #fbd13d;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    // width: 304px;
  }
  .mt44 {
    margin-top: 44px !important;
  }
}
</style>
